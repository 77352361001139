@media screen and (max-width:768px){
    .mob-view {
        display: block;
    }
    .exc-mob-view {
        display: none;
    }
}
@media screen and (min-width:769px){
    .mob-view {
        display: none;
    }
    .exc-mob-view {
        display: block;
    }
}

.between-center-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mob-view{    
    .stock{
        border: 1px solid #aca7a7;
        padding: 9px;      
        .pdc-info{           
            .cat_sts {
                label{
                    font-size: 11px;
                }
                svg{
                    color: green;
                    font-size: 10px;
                }
                small.status{
                        color: green;
                }                
            }
        }
        .update-sec{
            margin-top: 7px;
            padding-left: 5px;            
        }
        .actions{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 9px;
            button{
                font-size: 11px;
            }
        }
    }
}