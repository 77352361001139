
@media screen and (min-width: 900px) {
    #ord-por-home-img-block {
        display: flex;
    }    
}
@media screen and (max-width: 899px) {
    #ord-por-home-img-block {
        display: none;
    }    
}