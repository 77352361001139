.bank-acc-card {
    background: dodgerblue;
    padding: 10px;
    border-radius: 10px;
}
.bank-acc-card div#bank {
    text-align: center;
    color: #fff;
    border-right: 2px dashed #000;
    border-color: #79b9f8;
    padding-right: 2rem;
}
.bank-acc-card > div#bank > svg {
    margin-top: 2.3rem;
    font-size: 40px;
}

@media screen and (max-width: 1139px) {
    .bank-acc-card {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        div#bank {
            border-right: none;
            padding-right: 0;
            border-bottom: 2px dashed #79b9f8;
            h4 {
                margin-top: 0;
            }
        }
        div#info {
            text-align: center;
            h3{
                margin-bottom: 0;
                font-size: 14px;
            }
            h2{
                font-size: 21px;
                margin: 0;
            }
        }
    }    
}
@media screen and (min-width: 1140px) {
    .bank-acc-card {
        display: flex;
        justify-content: space-around;        
    }
}