
@media screen and (max-width: 768px) {
    .orders-mob {
        display: block;
    }
    .orders-exc-mob{
        display: none;
    }
}
@media screen and (min-width:769px) {
    .orders-mob {
        display: none;
    }
    .orders-exc-mob{
        display: block;
    }
}

.between-center-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orders-mob{    
    div:first-child{
        border: 1px solid #aca7a7;
        padding: 9px;      
        .order-info{
            h6{
                margin: 0;
                font-size: 13px;
                margin-bottom: -6px;
            }
            small{
                font-size: 12px;
            }
            .date_sts {
                margin-top: 3px;
                svg{
                    color: green;
                    font-size: 10px;
                }
                small.status{
                        color: green;
                }                
            }
        }
        .customer-info{
            margin-top: 7px;
            h5{
                margin: 0;
            }
            p{
                margin: 0;
                font-size: 12px;
            }
            address{
                font-size: 12px;
            }
        }
        .actions{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 9px;
            button{
                font-size: 11px;
            }
        }
    }
}