.ship-info {
    border: 1px dashed;
    border-radius: 10px;
    border-width: medium;    
    div.main-sec {
        padding-top: 5px;
        label {
            font-size: 10px;
        }
    }
}

@media screen and (max-width: 768px) {
    .ship-info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (min-width: 769px) {
    .ship-info {
        display: flex;
        justify-content: space-around;
        // flex-direction: row;
    }
}