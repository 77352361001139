.scrollable-sec{
    max-height: 418px;
    overflow: scroll;
    overflow-x: hidden;
}
.navi-sec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    div{
        button{
            border: 1px solid;
            font-size: 11px;
            small{
                background: #1976d2;
                color: #fff;
                margin: 0 5px 0 5px;
                padding: 4px 4px 4px 4px;
                border-radius: 50%;
                font-size: 24px;                
                line-height: 0.6;
                font-weight: 400;
            }
        }
    }
}